import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/home'),
    meta: {
      title: 'home',
      metaInfo: {
        title: '尚阳网络 - 智慧农业解决方案提供商',
        keywords: '尚阳,尚阳网络,智慧农业,智能控制,数字农业,耘尚农场',
        description:
          '尚阳网络深耕智慧农业多年,拥有丰富的产品研发与项目经验。我们旨在通过先进的信息化和智能化技术,帮助生产者进行科学高效的农事生产,促进农产品品质与产量提升'
      }
    }
  },
  {
    path: '/solution',
    component: () => import('@/views/solution'),
    meta: {
      title: 'solution',
      metaInfo: {
        title: '尚阳网络 - 智慧农业解决方案提供商',
        keywords: '智慧种植,智慧养殖,智慧农业,智能控制,薄膜大棚,玻璃温室,数字化,农产品品质,秸秆还田',
        description:
          '尚阳网络深耕智慧农业多年,拥有丰富的产品研发与项目经验。我们旨在通过先进的信息化和智能化技术,帮助生产者进行科学高效的农事生产,促进农产品品质与产量提升'
      }
    }
  },
  {
    path: '/product',
    component: () => import('@/views/product'),
    meta: {
      title: 'product',
      metaInfo: {
        title: '尚阳网络 - 智慧农业解决方案提供商',
        keywords: '环境监测,土壤传感器,温湿度传感器,气象站,智能堆肥,信息化,智能化,农事生产,智能控制,蓝莓采收,蓝莓',
        description:
          '尚阳网络深耕智慧农业多年,拥有丰富的产品研发与项目经验。我们旨在通过先进的信息化和智能化技术,帮助生产者进行科学高效的农事生产,促进农产品品质与产量提升'
      }
    }
  },
  {
    path: '/projectXinFeng',
    component: () => import('@/views/projectXinFeng'),
    meta: {
      title: 'projectXinFeng',
      metaInfo: {
        title: '尚阳网络 - 智慧农业解决方案提供商',
        keywords: '智慧农业,智能控制,环境监测,农产品品质,智慧种植,产量提升,鑫锋村',
        description:
          '尚阳网络深耕智慧农业多年,拥有丰富的产品研发与项目经验。我们旨在通过先进的信息化和智能化技术,帮助生产者进行科学高效的农事生产,促进农产品品质与产量提升'
      }
    }
  },
  {
    path: '/projectFanJing',
    component: () => import('@/views/projectFanJing'),
    meta: {
      title: 'projectFanJing',
      metaInfo: {
        title: '尚阳网络 - 智慧农业解决方案提供商',
        keywords: '智慧农业,智能控制,环境监测,农产品品质,智慧种植,产量提升,范泾,草莓种植',
        description:
          '尚阳网络深耕智慧农业多年,拥有丰富的产品研发与项目经验。我们旨在通过先进的信息化和智能化技术,帮助生产者进行科学高效的农事生产,促进农产品品质与产量提升'
      }
    }
  },

  {
    path: '/about',
    component: () => import('@/views/about'),
    meta: {
      title: 'about',
      metaInfo: {
        title: '尚阳网络 - 智慧农业解决方案提供商',
        keywords: '尚阳,尚阳网络,智慧农业,智能控制,数字农业,耘尚农场',
        description:
          '尚阳网络深耕智慧农业多年,拥有丰富的产品研发与项目经验。我们旨在通过先进的信息化和智能化技术,帮助生产者进行科学高效的农事生产,促进农产品品质与产量提升'
      }
    }
  },

  {
    path: '/test',
    component: () => import('@/views/test'),
    meta: {
      title: 'test',
      metaInfo: {
        title: '尚阳网络 - 智慧农业解决方案提供商',
        keywords: '气象站',
        description: '气象站一站式服务'
      }
    }
  }
]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.metaInfo) {
    store.commit('myVueMeta/CHANGE_META_INFO', to.meta.metaInfo)
  }
  next()
})

export default router
