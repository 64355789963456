<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="key" />
    </keep-alive>

    <router-view v-if="!$route.meta.keepAlive" :key="key" />
  </div>
</template>

<script>
export default {
  computed: {
    key() {
      return this.$route.fullPath
    }
  }
}
</script>

<style>
/* 设置滚动条样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

div::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background: #bcbcbc;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #a8a8a8;
}

::-webkit-scrollbar-thumb:vertical:active,
::-webkit-scrollbar-thumb:vertical:hover {
  background-color: #a8a8a8;
}
</style>
